import React from "react";

const PhoneEntryDetailsMob = (props) => {
  const entryDetails = props.entryDetails;

  return (
    <div
      id="PhoneEntryDetailsMob"
      className="d-flex w-100 flex-column justify-content-around align-items-center h-100 pb-5 pt-3 px-3 mt-2"
    >
      <div
        className="w-100 mt-5"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p>
          <span
            className="text-bold mtext text-small"
            style={{ fontSize: "20px" }}
          >
            Enter via Phone!
          </span>
        </p>
      </div>
      <div className="d-flex mt-3 justify-content-center flex-column flex-grow-1">
        <div className="call-mobile w-100 d-flex justify-content-center flex-column align-items-center">
          <div
            className="w-75 px-5 py-5 text-bold text-center pt-2 pb-2 enter-btn"
            onClick={() =>
              (window.location.href = `tel:${entryDetails.phoneEntryNumber}`)
            }
          >
            Click To Enter
          </div>

          <p
            className="mtext px-sm-5 mt-3 text-center text-xs text-bold mt-2 mb-4"
            style={{ fontSize: "13px", fontWeight: "700" }}
          >
            {entryDetails.phoneTermsText}
          </p>
        </div>
        <div
          id=""
          className="call-desktop text-md text-bold text-center mtext"
          style={{ fontSize: "35px" }}
        >
          <p className="">
            Call &nbsp;
            <span className="color-primary">
              {entryDetails.phoneEntryNumber}{" "}
            </span>
          </p>
          <p
            className="mtext px-sm-5 mt-2 text-center text-xs"
            style={{ fontSize: "13px", fontWeight: "700" }}
          >
            {entryDetails.phoneTermsText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhoneEntryDetailsMob;
