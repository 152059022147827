import React from "react";
import { siteDataAtom } from "../atoms";
import { useRecoilValue } from "recoil";
import { useHistory, useRouteMatch } from "react-router-dom";

function NavigationTop() {
  const siteData = useRecoilValue(siteDataAtom);
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <div className="container-fluid  nav-top-container p-0 m-0">
      <div className="col-sm float-start d-flex align-items-center">
        <div>
          {siteData && (
            <img
              onClick={() => (window.location.href = "/")}
              className="logo-img-small"
              src={siteData.site_styling[0].logo_image}
            />
          )}
        </div>
      </div>
      <div className="col-sm d-flex d-lg-none justify-content-end p-0 m-0">
        <div
          class="p-0 m-0"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            class="btn dropdown-btn"
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "black",
            }}
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div>
              <i
                class="fas fa-align-justify fa-1x"
                style={{ fontSize: "23px" }}
              ></i>
            </div>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li onClick={() => [(window.location.href = "/")]}>
              <a class="dropdown-item">Competitions</a>
            </li>
            <li onClick={() => [(window.location.href = "/winners")]}>
              <a class="dropdown-item">Winners</a>
            </li>

            <li onClick={() => (window.location.href = "/terms")}>
              <a class="dropdown-item">Terms &amp; Conditions</a>
            </li>
            <li>
              <a
                class="dropdown-item"
                onClick={() =>
                  (window.location.href =
                    "https://www.reachplc.com/site-services/privacy-policy")
                }
              >
                Privacy Notice
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                onClick={() =>
                  (window.location.href = "https://www.reachplc.com/our-brands")
                }
              >
                Reach Brands
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-sm d-none d-lg-flex float-end d-flex align-items-center nav-items">
        <div onClick={() => [(window.location.href = "/")]}>
          Live Competitions
        </div>
        <div onClick={() => [(window.location.href = "/winners")]}>Winners</div>

        <div onClick={() => history.push("/terms")}>Terms {`&`} Conditions</div>
      </div>
    </div>
  );
}
export default NavigationTop;
