import React, { useEffect, useState } from "react";
import EntryOptionsSelector from "../../EntryOptionsSlector";
import { payWithFonixPay } from "../../PSPUtils";
import { useLocation } from "react-router";
import checkboxdeselected from "../../../../../Images/checkboxdeselected.png";
import checkboxselected from "../../../../../Images/checkboxselected.png";
import { useRecoilState, useRecoilValue } from "recoil";
import { modalAtom, siteDataAtom } from "../../../../../atoms";
import { cardDetailsAtom } from "../../../../../atoms";

const OnlineEntryDetailsMob = (props) => {
  const entryDetails = props.entryDetails;
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [termsSelected, setTermsSelected] = useState(false);
  const [optinSelected, setOptinSelected] = useState(true);
  const [error, setError] = useState("");
  const siteData = useRecoilValue(siteDataAtom);

  const location = useLocation();

  const site = location.pathname.split("/")[1];

  /**
   * A pay by x has been clicked...
   */
  const [busy, setBusy] = useState(false);
  const [modal, setModal] = useRecoilState(modalAtom);
  const [cardDetails, setCardDetails] = useRecoilState(cardDetailsAtom);

  const meh = entryDetails.onlineEntryOptions.map((it) => {
    return { ...it, description: it.description };
  });

  const submitCard = () => {
    if (selectedOption === null) {
      setError("Error: Please select how many entries you would like.");
      //alert("Error: Please select how many entries you would like.");
      return;
    }
    if (termsSelected != true) {
      setError("Error: Please accept the terms and conditions.");
      //alert("Error: Please accept the terms and conditions.");
      return;
    }
    var array = {};
    array["entry_details"] = entryDetails;
    array["marketing_opt"] = optinSelected;
    array["selected_bundle"] = selectedOption;
    console.log(array);
    setCardDetails(array);
    setModal({ name: "cardDetails" });
  };

  const submitPhone = () => {
    if (selectedOption === null) {
      setError("Error: Please select how many entries you would like.");
      //alert("Error: Please select how many entries you would like.");
      return;
    }
    if (termsSelected !== true) {
      setError("Error: Please accept the terms and conditions.");
      //alert("Error: Please accept the terms and conditions.");
      return;
    }
    setBusy(true);

    payWithFonixPay(selectedOption, props.entryDetails.window_id, optinSelected)
      .then((ok) => {
        setBusy(true);
        window.location.href = ok.data.url;
      })
      .catch((err) => {
        alert(JSON.stringify(err));
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <div
      id="OnlineEntryDetailsMob"
      className="d-flex flex-column justify-content-around align-items-center flex-grow-1"
    >
      <div className="d-flex w-100 mt-3 flex-column text-center mtext text-sm">
        <span className="text-xbold mtext text-sm">
          Select number of entries
        </span>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <EntryOptionsSelector
            entryOptions={entryDetails ? meh : []}
            callback={(option) => setSelectedOption(option)}
          />
        </div>

        <div
          className="row"
          style={{ paddingTop: "50px", minHeight: "60px", textAlign: "left" }}
        >
          <div className="col-auto d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              onClick={() => [setTermsSelected(!termsSelected)]}
              id="terms"
              name="terms"
              checked={termsSelected}
            />
          </div>
          <div
            onClick={() => setTermsSelected((termsSelected) => !termsSelected)}
            className="col d-flex"
            style={{ color: "#434343" }}
          >
            <div
              style={{ color: "red", paddingRight: "5px", cursor: "pointer" }}
            >
              *
            </div>
            <div style={{ cursor: "pointer" }}>
              {" "}
              I confirm that I am over the age of 18 and have read and
              understood the
              <a href="/terms"> Terms & Conditions </a>
              and{" "}
              <a href="https://www.reachplc.com/site-services/privacy-policy">
                {" "}
                Privacy Policy.{" "}
              </a>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{ paddingTop: "50px", minHeight: "60px", textAlign: "left" }}
        >
          <div className="col-auto d-flex align-items-start justify-content-center pt-2">
            <input
              type="checkbox"
              onClick={() => [setOptinSelected(!optinSelected)]}
              id="terms"
              name="terms"
              checked={optinSelected}
            />
          </div>
          <div
            onClick={() => setOptinSelected((optinSelected) => !optinSelected)}
            className="col d-flex"
            style={{ color: "#434343" }}
          >
            <div
              style={{ color: "red", paddingRight: "5px", cursor: "pointer" }}
            >
              *
            </div>
            <div>
              <div
                style={{ cursor: "pointer" }}
                dangerouslySetInnerHTML={{
                  __html: siteData?.site_styling[0].opt_in_html ?? "",
                }}
              />
            </div>
          </div>
        </div>

        <span className="text-rg text-bold mt-5 mtext text-sm">
          Payment Options
        </span>

        <div className="row d-flex justify-content-between  mb-5">
          {entryDetails.onlineEntryOptions[0].amount !== 0 && (
            <div className="col-12 d-flex justify-content-center">
              <button
                className={`paybutton shadow competition  ${
                  selectedOption ? "selected" : ""
                }`}
                id="PayByMobile"
                onClick={() => [
                  submitPhone(),
                  window.dataLayer.push({
                    event: "Pay By Mobile - Clicked",
                  }),
                ]}
              >
                <div className="text-rg text-bold px-5 py-0">Pay by Mobile</div>
              </button>
            </div>
          )}

          {entryDetails.onlineEntryOptions[0].amount !== 0 && (
            <div className="col-12 d-flex justify-content-center">
              <button
                className="paybutton shadow"
                onClick={() => [
                  submitCard(),
                  window.dataLayer.push({
                    event: "Pay By Card - Clicked",
                  }),
                ]}
                id="PayByCard"
              >
                <div className="text-rg text-bold px-5 py-0">Pay by Card</div>
              </button>
            </div>
          )}

          {entryDetails.onlineEntryOptions[0].amount === 0 && (
            <div className="col-12 d-flex justify-content-center">
              <button
                className="paybutton shadow"
                onClick={() => [submitCard()]}
                id="PayByCard"
              >
                <div className="text-rg text-bold px-5 py-0">Continue</div>
              </button>
            </div>
          )}
          <div style={{ paddingTop: "20px", color: "red" }}>{error}</div>
        </div>

        <div className="row">
          <div className="col-12" style={{ textAlign: "left" }}>
            Pay by Mobile will be charged to your mobile phone bill or deducted
            from your phone credit.
          </div>
          <div
            className="col-12 text-bold pt-3 pb-5"
            style={{ textAlign: "left" }}
          >
            NB. ONLY available on O2, Vodafone, EE and Three. Other network
            users please use the Card option.
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineEntryDetailsMob;
