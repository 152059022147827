import React from "react";
import "./Footer.css";
import { useHistory } from "react-router-dom";

function Footer() {
  const history = useHistory();
  return (
    <div id="FooterContainer" className="container-fluid footer-main-container">
      <div className="row d-flex align-items-center pb-5 pt-5">
        <div className="col-sm-12 col-md-4 d-flex justify-content-center mt-4">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/terms")}
          >
            TERMS AND CONDITIONS
          </div>
        </div>
        <div
          onClick={() =>
            (window.location.href =
              "https://www.reachplc.com/site-services/privacy-policy")
          }
          className="col-sm-12 col-md-4 d-flex justify-content-center mt-4"
        >
          <div style={{ cursor: "pointer" }}>PRIVACY NOTICE</div>
        </div>
        <div
          onClick={() =>
            (window.location.href = "https://www.reachplc.com/our-brands")
          }
          className="col-sm-12 col-md-4 d-flex justify-content-center mt-4"
        >
          <div style={{ cursor: "pointer" }}>REACH BRANDS</div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
