import { atom } from "recoil";

export const siteDataAtom = atom({
  key: "siteData", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const modalAtom = atom({
  key: "modalName", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const cardDetailsAtom = atom({
  key: "cardDetails", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const compDataAtom = atom({
  key: "compData", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});
