import { useEffect, useState } from "react";
import axios from "axios";

function Winners() {
  const [winners, setWinners] = useState();

  useEffect(() => {
    axios
      .get("https://winner-demosite.mi-api.co.uk/api/get_winners.php")
      .then(function (response) {
        // handle success
        setWinners(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);
  return (
    <div class="container-md" style={{ minHeight: "70vh" }}>
      <div class="row our-winners-block mb-4">Our Winners</div>
      <div className="container">
        <div className="row">
          {winners &&
            winners.map(function (object, i) {
              return (
                <div
                  class="col col-12 col-lg-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    class="row"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      style={{ maxWidth: "600px" }}
                      src={object.competition_image}
                    />
                  </div>
                  <div class="row comp-details pt-4" style={{ flexGrow: 1 }}>
                    <div className="comp-details-bold">Competition:</div>
                    <div>{object.competiton_name}</div>
                  </div>
                  <div class="row comp-details">
                    <div className="comp-details-bold">End Date:</div>
                    <div>{object.end_time}</div>
                  </div>
                  <div class="row comp-details pt-3 pb-5">
                    <div className="comp-details-bold">Winner:</div>
                    <div>{object.winner ?? "To be announced"}</div>
                  </div>
                  <div className="row pb-4">
                    <hr className="new4" />
                  </div>
                </div>
              );
            })}
        </div>
        {!winners && (
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
            }}
          >
            <div class="lds-ripple2">
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Winners;
