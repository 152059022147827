import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { modalAtom, compDataAtom } from "../../../../atoms";

import axios from "../../../../Utils/AppAxios";
import { ONLINE, PHONE, POSTAL, SMS } from "../EntryTypes";
import EntryTypeSelector from "../EntryTypeSlector";
import Banner from "./parts/Banner";
import OnlineEntryDetailsMob from "./parts/OnlineEntryDetailsMob";
import PhoneEntryDetailsMob from "./parts/PhoneEntryDetailsMob";
import PostEntryDetailsMob from "./parts/PostEntryDetailsMob";
import SMSEntryDetailsMob from "./parts/SMSEntryDetailsMob";
import "../../../../css/EntryModalMobile.css";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

function MobileEntryModal() {
  const [entryDetails, setEntryDetails] = useState(null);
  const [selectedEntryType, setSelectedEntryType] = useState(ONLINE);
  const [showTerms, setShowTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const [modal, setModal] = useRecoilState(modalAtom);
  const [compData, setCompData] = useRecoilState(compDataAtom);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const comp = params.get("comp");

    console.log(modal);
    axios
      .get(`get_comp.php`, { params: { comp: location.pathname } })
      .then((response) => {
        console.log(response);
        setEntryDetails(response.data);
        setCompData(response.data);
        setShowModal(true);
        setLoading(false);

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        });
      })
      .catch((err) => {
        history.push("/");
        console.error(err);
      });
  }, [modal]);

  let componentToShow = null;
  switch (selectedEntryType) {
    case ONLINE:
      componentToShow = <OnlineEntryDetailsMob entryDetails={entryDetails} />;
      break;
    case SMS:
      componentToShow = <SMSEntryDetailsMob entryDetails={entryDetails} />;
      break;
    case PHONE:
      componentToShow = <PhoneEntryDetailsMob entryDetails={entryDetails} />;
      break;
    case POSTAL:
      componentToShow = <PostEntryDetailsMob entryDetails={entryDetails} />;
      break;
  }

  return (
    <div>
      {loading === true && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div class="lds-ripple2">
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div
        id="MobileEntryModal"
        class="modal-dialog  modal-fullscreen-md-down modal-l"
        style={{
          width: "100%",
          height: "100%",
          display: showModal === true ? "flex" : "none",
        }}
      >
        <div class="modal-content rounded-0 w-100" style={{ height: "100%" }}>
          <div class=" p-0" style={{ backgroundColor: "white" }}>
            <div className="row">
              <Banner
                text={entryDetails ? entryDetails.entryTitle : ""}
                closeModal={() => history.goBack()}
              ></Banner>
            </div>
            <div
              className="row"
              style={{ backgroundColor: "white", justifyContent: "center" }}
            >
              <img
                className="modal-image p-0"
                style={{
                  maxWidth: "600px",
                  maxHeight: "300px",
                  objectFit: "cover",
                }}
                alt=""
                src={entryDetails ? entryDetails.competitionImage : ""}
              />
            </div>
            <div
              id="CompeitionEntryTextMob"
              className="row text-rg text-bold mtext text-center py-4 py-lg-5 text-sm px-4"
              style={{ whiteSpace: "pre-line" }}
            >
              {entryDetails ? entryDetails.competitionText : ""}
            </div>
            <div
              className="row flex-grow-1 px-3 "
              style={{ backgroundColor: "white" }}
            >
              <EntryTypeSelector
                entryTypes={entryDetails ? entryDetails.entryTypes : []}
                onSelected={(option) => setSelectedEntryType(option)}
              />
              {entryDetails ? componentToShow : ""}
            </div>

            {entryDetails && (
              <div
                id="EntryFooterMob"
                className="row  py-3 px-3 mtext text-sm text-bold"
                style={{ textAlign: "center" }}
              >
                <p>
                  <span>{entryDetails ? entryDetails.footer_text : "..."}</span>
                </p>
                <p className="mt-3">
                  <span>
                    <u
                      style={{ cursor: "pointer" }}
                      onClick={() => [setModal(), history.push("/terms")]}
                    >
                      Full Terms &amp; Conditions
                    </u>
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default MobileEntryModal;
