import React, { useEffect, useState } from "react";
import CompetitionCard from "./CompetitionCard";
import PinterestGrid from "rc-pinterest-grid";
import { useRecoilValue } from "recoil";
import { siteDataAtom } from "../atoms";

function CompetitionsList() {
  const siteData = useRecoilValue(siteDataAtom);

  useEffect(() => {});

  return (
    <div className="container m-0 p-0">
      <div
        id="CardsContainer"
        class="container-lg cards-container"
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <div className="row text-center pt-4 comp-grid-title m-0">
          <div className="col-4 align-items-center justify-content-end d-flex">
            <div
              style={{
                borderTop: "1px solid #222222",
                height: "1px",
                width: "90%",
              }}
            />
          </div>
          <div className="col-4 text-center">Live Competitions</div>
          <div className="col-4 align-items-center justify-content-start d-flex">
            <div
              style={{
                borderTop: "1px solid #222222",
                height: "1px",
                width: "90%",
              }}
            />
          </div>
        </div>
        <div className="row d-flex  justify-content-center  m-0 ">
          {siteData &&
            siteData.competitions.map((object, index) => (
              <div
                className="col col-6 col-lg-4 col-xl-3 p-2"
                style={{ height: "100%" }}
              >
                <CompetitionCard index={index} data={object} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
export default CompetitionsList;
