import "./App.css";
import "./css/Page.css";
import NavigationTop from "./Components/NavigationTop";
import LiveCompetitions from "./Pages/LiveCompetitions";
import Footer from "./Components/Footer";
import EntryModal from "./Components/modals/entry/EntryModal";
import axios from "axios";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { siteDataAtom, modalAtom } from "./atoms";
import { Switch, Route } from "react-router-dom";
import SuccessFonix from "./Pages/SuccessFonix";
import FailureFonix from "./Pages/FailureFonix";
import Winners from "./Pages/Winners";
import Terms from "./Pages/Terms";
import MobileEntryModal from "./Components/modals/entry/mobile/MobileEntryModal";
import CardDetails from "./Components/modals/CardDetails";
import SEO from "./SEO";
import "./css/EntryModalMobile.css";
import { useHistory } from "react-router-dom";
import Words from "./Pages/Words";

/**
 * Returns the app.
 * @returns The app
 */
function App() {
  const [siteData, setsiteData] = useRecoilState(siteDataAtom);
  const modal = useRecoilValue(modalAtom);
  const [comp, setComp] = useState();
  const history = useHistory();

  useEffect(() => {
    var tagID = "";

    console.log(window.location.host);
    if (
      window.location.host === "www.mirror.win" ||
      window.location.host === "mirror.win"
    ) {
      tagID = "GTM-WRKPMM3";
    }
    if (
      window.location.host === "www.express.win" ||
      window.location.host === "express.win"
    ) {
      tagID = "GTM-5XQXFLF";
    }
    if (
      window.location.host === "www.dailystar.win" ||
      window.location.host === "dailystar.win"
    ) {
      tagID = "GTM-W7ZXWZS";
    }
    if (
      window.location.host === "www.regional.win" ||
      window.location.host === "regional.win"
    ) {
      tagID = "GTM-MDXT692";
    }
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML =
      "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" +
      tagID +
      "');";
    document.head.appendChild(s);

    const s2 = document.createElement("noscript");
    s2.type = "text/javascript";
    s2.async = true;
    s2.innerHTML =
      '<iframe src="https://www.googletagmanager.com/ns.html?id=' +
      tagID +
      '"height="0" width="0" style="display:none;visibility:hidden"></iframe>';
    document.body.appendChild(s);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const comp = params.get("comp");

    setComp(comp);

    const pathname = window.location.pathname;
    if (pathname === "/paymentsuccess") {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const id = params.get("id");

      axios
        .get("https://mirror.win/api/payment_status.php?id=" + id)
        .then(function (response) {
          console.log(response);
          var re = new RegExp("^(000.000.|000.100.1|000.[36])");
          if (re.test(response.data.result.code)) {
            history.push("/success");
          } else {
            console.log(response.data);
            alert(response.data.result.description);
            // window.location.href = "/";
          }
        })
        .catch(function (error) {
          // handle error
          alert("An error occured. [1746]");
          window.location.href = "/";
        })
        .then(function () {
          // always executed
        });
    }
    axios
      //.get("https://compdev.mi-api.co.uk/api/getsitedata.php")
      .get("https://mirror.win/api/getsitedata.php")
      .then(function (response) {
        // handle success
        setsiteData(response.data);

        // Set Color variable
        document.documentElement.style.setProperty(
          "--hilight",
          response.data.site_styling[0].primary_color
        );

        // Set Gradient Color 1 variable
        document.documentElement.style.setProperty(
          "--gradient-1",
          response.data.site_styling[0].gradient_1
        );
        // Set Gradient Color 2 variable
        document.documentElement.style.setProperty(
          "--gradient-2",
          response.data.site_styling[0].gradient_2
        );

        // Set Favicon URL
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = response.data.site_styling[0].favicon;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  const api_regex = /^\/api\/.*/;
  // if using "/api/" in the pathname, don't use React Router

  if (api_regex.test(window.location.pathname)) {
    return <div />; // must return at least an empty div
  } else {
    return (
      <>
        <div className="container-fluid p-0 m-0 app">
          {siteData && (
            <SEO
              title={siteData.site_styling[0].page_title}
              keywords={""}
              description={siteData.site_styling[0].page_description}
            />
          )}

          <div class="row justify-content-center m-0">
            <div className="p-0">
              <NavigationTop />
            </div>
          </div>
          <Switch>
            <Route path="/success">
              <div class="row p-0 m-0">
                <div className="container-fluid p-5 m-0">
                  <SuccessFonix></SuccessFonix>
                </div>
              </div>
            </Route>
            {/* A fail route */}
            <Route path="/failure">
              <div class="row p-0 m-0">
                <div className="container-fluid p-5 m-0">
                  <FailureFonix />
                </div>
              </div>
            </Route>
            <Route path="/winners">
              <div>
                <Winners />
              </div>
            </Route>
            <Route path={["/terms", "/terms"]}>
              <Terms />
            </Route>
            <Route path="/words">
              <Words />
            </Route>

            <Route exact path="/">
              <div class="row p-0 m-0" style={{ height: "100%" }}>
                <div
                  className="container-fluid p-0 m-0"
                  style={{ position: "relative" }}
                >
                  <LiveCompetitions />
                </div>
              </div>
            </Route>
            <Route path="/paymentsuccess/:id?">
              <div class="row p-0 m-0">
                <div
                  className="container-fluid p-0 m-0"
                  style={{ position: "relative" }}
                >
                  <LiveCompetitions />
                </div>
              </div>
            </Route>
            <Route path="/*">
              <div class="row p-0 m-0">
                <div
                  className="container-fluid p-0 m-0 d-none d-md-flex"
                  style={{ position: "relative" }}
                >
                  <LiveCompetitions />
                  <EntryModal />
                </div>
                <div className="container-fluid p-0 m-0 d-flex d-md-none">
                  <MobileEntryModal />
                </div>
              </div>
            </Route>
          </Switch>

          <div id="FooterWrapper" class="row footer-row p-0 m-0">
            <Footer />
          </div>
        </div>

        {modal && modal.name === "cardDetails" && (
          <>
            <CardDetails />
          </>
        )}
      </>
    );
  }
}
export default App;
