import React from "react";

const SMSEntryDetailsMob = (props) => {
  const entryDetails = props.entryDetails;

  return (
    <div
      id="SMSEntryDetailsMob"
      className="d-flex w-100 flex-column justify-content-around align-items-center h-100 pb-5 pt-3 px-3 mt-5"
    >
      <div
        className="w-100 mt-2"
        style={{ display: "flex", justifyContent: "center", fontSize: "20px" }}
      >
        <p>
          <span className="text-bold mtext text-small">Enter via SMS!</span>
        </p>
      </div>
      <div className="d-flex mt-3 justify-content-center flex-column flex-grow-1">
        <div className="w-100 d-flex justify-content-center flex-column align-items-center call-mobile">
          <div
            className="w-75  px-5 py-5 text-center text-bold enter-btn pt-2 pb-2"
            onClick={() =>
              (window.location.href = `sms://${entryDetails.smsShortcode};?&body=${entryDetails.smsKeyword}`)
            }
          >
            Click To Enter
          </div>

          <p
            className="mtext px-sm-5 text-center  mt-2 mb-4"
            style={{ fontSize: "20px", fontWeight: "300", fontWeight: "700" }}
          >
            {entryDetails.smsTermsText}
          </p>
        </div>
        <div
          id=""
          className="text-md text-bold text-center mtext"
          style={{ fontSize: "35px" }}
        >
          <p className="">
            TEXT{" "}
            <span className="color-primary">{entryDetails.smsKeyword} </span>
            to{" "}
            <span className="color-primary">{entryDetails.smsShortcode}</span>
          </p>
          <p
            className="mtext px-sm-5 text-center text-xs mt-0 mb-4"
            style={{ fontSize: "20px", fontWeight: "700" }}
          >
            {entryDetails.smsTermsText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SMSEntryDetailsMob;
