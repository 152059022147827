import React, { useEffect, useState } from "react";
import axios from "axios";

function Terms() {
  const [terms, setTerms] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://mirror.win/api/getterms.php?url=" + window.location.hostname
      )
      .then(function (response) {
        // handle success
        setTerms(response.data[0].main_terms);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  return (
    <>
      <div className="container" style={{ maxWidth: "800px" }}>
        <div className="row text-lg pt-5 pb-3 ml-2 pr-2">
          <div>Terms</div>
        </div>

        <div className="row pb-5">
          <div
            dangerouslySetInnerHTML={{
              __html: terms,
            }}
          />
        </div>
      </div>
    </>
  );
}
export default Terms;
