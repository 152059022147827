import React, { useEffect } from "react";
import { ONLINE, POSTAL, SMS, PHONE } from "./EntryTypes";

const EntryTypeSelector = (props) => {
  const [selected, setSelected] = React.useState(false);

  useEffect(() => {
    console.log(props.entryTypes);
    if (!selected) {
      setSelected(props.entryTypes ? props.entryTypes[0] : "");
      if (props.entryTypes) {
        props.onSelected(props.entryTypes[0]);
      }
    }
  }, [props, selected]);

  const types = [ONLINE, SMS, PHONE, POSTAL];

  const buttonTitles = {
    ONLINE: "Online",
    POSTAL: "Postal",
    SMS: "SMS",
    PHONE: "Phone",
  };

  function onClicked(it) {
    if (props.entryTypes.includes(it)) {
      setSelected(it);
      props.onSelected(it);
      window.dataLayer.push({
        event: it + " Entry - Clicked",
      });
    }
  }

  const buttons = types.map((it) => {
    return (
      <div className="wrapper p-0 col col-3 col-sm-3 competition">
        <div
          id={it}
          className={`btn-primary noscale text-bold d-flex justify-content-center align-items-center ${
            selected === it ? "selected" : ""
          } ${props.entryTypes.includes(it) ? "" : "darken"}`}
          entryType={it}
          onClick={() => onClicked(it)}
        >
          {buttonTitles[it]}
        </div>
      </div>
    );
  });

  return (
    <>
      {props.entryTypes.length > 0 && (
        <div
          id="EntryTypeSelectorButtonHolder"
          className="row m-0 d-flex justify-content-center"
        >
          {buttons}
        </div>
      )}
    </>
  );
};

export default EntryTypeSelector;
