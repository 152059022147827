import Axios from "axios";

let baseUrl = process.env.REACT_APP_API_URL;

const axios = Axios.create({
  baseURL: baseUrl,
  withCredentials: false,
});

axios.interceptors.request.use((req) => {
  return req;
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status == 401 && window.location.pathname !== "/login") {
      window.location = "/login";
    }
    return Promise.reject(err);
  }
);

export default axios;
