import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

function SuccessFonix() {
  const [tick, setTick] = React.useState(5);

  const history = useHistory();

  const location = useLocation();

  const pathName = location.pathname;
  const site = pathName.split("/")[1];

  return (
    <div className="fs row m-0">
      <div
        className="col-12 text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: "900px" }}>
          <div className="row pt-3">
            <span style={{ fontSize: "25px", fontWeight: "600" }}>
              You're in!
            </span>
          </div>
          <div className="row pt-5">
            <span style={{ fontSize: "15px", fontWeight: "600" }}>
              Thanks for entering! You will be contacted at the end of the prize
              draw if you are the winner. Good luck
            </span>
          </div>
          <div className="row pt-5">
            <button
              type="button"
              class="btn-light mt-4 pt-3 pb-3 shadow"
              onClick={() => history.push("/")}
            >
              Back to All Competitions
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SuccessFonix;
