import "../../../../../css/EntryModalMobile.css";

function Banner(props) {
  return (
    <div
      id="Banner"
      className="w-100 py-0 m-0 bg-hilight d-flex flex-row justify-space-around align-items-center bg-hilight"
    >
      <div className="flex-grow-1 p-0 m-0 justify-content-center">
        <div
          className="text-white"
          style={{ width: "100%", textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      </div>
      <div style={{ right: 0 }}>
        <button className="rounded m-3" onClick={() => props.closeModal()}>
          X
        </button>
      </div>
    </div>
  );
}

export default Banner;
