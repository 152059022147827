import React, { forwardRef, useEffect, useState } from "react";
import TennisImage from "../Images/tennisimage.jpg";
import LeftArrow from "../Images/left-arrow.png";
import RightArrow from "../Images/right-arrow.png";
import { siteDataAtom } from "../atoms";
import { useRecoilValue, useRecoilState } from "recoil";
import { CountUp } from "countup.js";
import { Parallax } from "react-parallax";
import { modalAtom } from "../atoms";
import { useHistory } from "react-router-dom";

function HeaderScroller(props) {
  const siteData = useRecoilValue(siteDataAtom);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [countUpComplete, setCountUpComplete] = useState(false);
  const [intervalValue, setIntervalValue] = useState();
  const [modal, setModal] = useRecoilState(modalAtom);
  const history = useHistory();

  var options = {
    formattingFn: (n) => formater(n),
  };

  function formater(number) {
    return padDigits(number, 2);
  }

  useEffect(() => {
    if (siteData) {
      setCurrentIndex(props.featured);
    }
    if (siteData && countUpComplete === false) {
      setCountUpComplete(true);
      startTimer();
      var ftime = siteData.competitions[currentIndex]?.end_time.replace(
        /-/g,
        "/"
      );
      var dateFuture = Date.parse(ftime);
      var dateNow = new Date().getTime();

      var seconds = Math.floor((dateFuture - dateNow) / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);
      if (dateFuture > dateNow) {
        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds =
          seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
      } else {
        days = 0;
        hours = 0;
        minutes = 0;
        seconds = 0;
      }

      var countUpDays = new CountUp("targetDays", days, options);
      var countUpMins = new CountUp("targetMins", minutes, options);
      var countUpHours = new CountUp("targetHours", hours, options);
      var countUpSeconds = new CountUp("targetSeconds", seconds, options);

      countUpMins.start();
      countUpHours.start();
      countUpSeconds.start();
      countUpDays.start();
    }
  });

  const startTimer = () => {
    setIntervalValue(
      setInterval(() => {
        if (siteData && siteData.competitions[currentIndex]) {
          var ftime = siteData.competitions[currentIndex]?.end_time.replace(
            /-/g,
            "/"
          );
          var dateFuture = Date.parse(ftime);
          var dateNow = new Date().getTime();

          var seconds = Math.floor((dateFuture - dateNow) / 1000);
          var minutes = Math.floor(seconds / 60);
          var hours = Math.floor(minutes / 60);
          var days = Math.floor(hours / 24);
          if (dateFuture > dateNow) {
            setDays(padDigits(days, 2));
            hours = hours - days * 24;
            setHours(padDigits(hours, 2));
            minutes = minutes - days * 24 * 60 - hours * 60;
            setMins(padDigits(minutes, 2));
            seconds =
              seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
            setSeconds(padDigits(seconds, 2));
          } else {
            setDays("00");
            setHours("00");
            setMins("00");
            setSeconds("00");
          }
        }
      }, 1000)
    );
  };

  function padDigits(number, digits) {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    );
  }

  const forward = (currentIndex) => {
    setCountUpComplete(false);
    setIntervalValue(clearInterval(intervalValue));

    if (currentIndex < siteData.competitions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const backward = (currentIndex) => {
    setCountUpComplete(false);
    setIntervalValue(clearInterval(intervalValue));

    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(siteData.competitions.length - 1);
    }
  };

  return (
    <div className="container-fluid  p-0 m-0">
      <div
        className="row"
        style={{
          backgroundColor: "#F5F4F4",
          marginRight: "0px",
        }}
      >
        <div
          className="col p-0"
          style={{
            width: "45%",
            flex: "0 0 45%",
            maxWidth: "45%",
            minHeight: "700px",
          }}
        >
          <div
            className="container-fluid inner-container"
            style={{ paddingTop: "100px", paddingBottom: "100px" }}
          >
            <div className="row">
              <div className="col">
                <div className="container-fluid left-col p-0">
                  <div className="row header-row-desktop">
                    <div className="col-auto arrow-col"></div>
                    {siteData && (
                      <div className="col">
                        <div className="row header-title">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                siteData.competitions[currentIndex]
                                  ?.competition_title,
                            }}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12 text-center sub-text-header">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteData.competitions[currentIndex]
                                    ?.competition_subtitle,
                              }}
                            />
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                          <button
                            onClick={() => [
                              history.push(
                                siteData.competitions[currentIndex].slug
                              ),
                              window.dataLayer.push({
                                event: "Main Banner - Enter Now - Clicked",
                              }),
                            ]}
                            type="button"
                            id="EnterNowBannerButton"
                            class="btn btn-danger mt-4 pt-2 pb-2 competition"
                          >
                            ENTER NOW
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-12 pt-3 sub-text-header">
                            Competition Ends
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center pt-3">
                          <div className="col" style={{ maxWidth: "350px" }}>
                            <div className="row timer-text timer-row pb-8">
                              <div className="col-auto text-center">
                                <div className="row" id="targetDays">
                                  {days}
                                </div>
                                <div className="row timer-sub-text">Days</div>
                              </div>
                              <div className="col-auto text-center">:</div>
                              <div className="col-auto text-center">
                                <div id="targetHours" className="row">
                                  {hours}
                                </div>
                                <div className="row timer-sub-text">Hours</div>
                              </div>
                              <div className="col-auto text-center">:</div>
                              <div className="col-auto text-center">
                                <div id="targetMins" className="row">
                                  {mins}
                                </div>
                                <div className="row timer-sub-text">
                                  Minutes
                                </div>
                              </div>
                              <div className="col-auto text-center">:</div>
                              <div className="col-auto">
                                <div id="targetSeconds" className="row">
                                  {seconds}
                                </div>
                                <div className="row timer-sub-text">
                                  Seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col p-0 left d-flex align-items-center"
          style={{
            width: "55%",
            flex: "55% 0 0",
            maxWidth: "55%",

            overflow: "hidden",
          }}
        >
          {siteData && (
            <Parallax
              className="react-parallax-header"
              bgImage={
                siteData.competitions[currentIndex]?.competition_image_large
              }
              strength={500}
            >
              <div style={{ height: "100%", width: "100%" }} />
            </Parallax>
          )}
        </div>
      </div>
    </div>
  );
}
export default HeaderScroller;
