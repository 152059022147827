import { siteDataAtom } from "../../atoms";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import axios from "axios";

function BreakModal() {
  const siteData = useRecoilValue(siteDataAtom);
  const [success, setSuccess] = useState();
  const [email, setEmail] = useState();
  const [optinSelected, setOptinSelected] = useState(true);

  function submit() {
    axios
      .post("https://winner-demosite.mi-api.co.uk/api/submit_email.php", {
        email: email,
        marketing: optinSelected,
      })
      .then(function (response) {
        console.log(response);
        setSuccess(true);
      })
      .catch(function (error) {
        // handle error
        setSuccess(false);
      })
      .then(function () {
        // always executed
      });
  }

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.65)",
        position: "absolute",
        zIndex: 800,
        top: "0px",
        height: "100%",
      }}
    >
      <div
        className="row"
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div className="col pt-3">
          <div
            className="container"
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              maxWidth: "700px",
            }}
          >
            <div
              className="row pt-4 pb-4 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {siteData && (
                <img
                  onClick={() => (window.location.href = "/")}
                  className="logo-img-medium"
                  src={siteData.site_styling[0].logo_image}
                />
              )}
            </div>
            <div className="row modal-row">
              We're taking a short break over the christmas period.
            </div>
            <div
              className="row modal-row pt-4 pb-4"
              style={{ fontWeight: "400" }}
            >
              We will be back in the New Year with a huge selection of
              competitions and new chances to win amazing prizes!
            </div>
            <div className="row modal-row pb-4" style={{ fontWeight: "400" }}>
              To keep updated with the latest competitions, enter your email
              address below:
            </div>
            <div
              className="row"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <div
                style={{
                  borderRadius: "8px",
                  paddingTop: "8px",
                  paddingBottom: "8px",

                  maxWidth: "300px",
                }}
                className="shadow"
              >
                <input
                  placeholder="Enter Email Address"
                  type="text"
                  id="email"
                  name="email"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  onChange={(e) => [setSuccess(), setEmail(e.target.value)]}
                />
              </div>
            </div>
            {success === true && (
              <div
                className="row"
                style={{ textAlign: "center", justifyContent: "center" }}
              >
                Your email was sent successfully.
              </div>
            )}
            {success === false && (
              <div
                className="row"
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  color: "red",
                }}
              >
                There was an error, please try later.
              </div>
            )}

            {siteData && (
              <div
                className="row pt-4"
                style={{
                  minHeight: "60px",
                  textAlign: "left",
                }}
              >
                <div className="col-auto d-flex align-items-start justify-content-center pt-2">
                  <input
                    type="checkbox"
                    onClick={() => [setOptinSelected(!optinSelected)]}
                    id="terms"
                    name="terms"
                    checked={optinSelected}
                  />
                </div>
                <div
                  onClick={() =>
                    setOptinSelected((optinSelected) => !optinSelected)
                  }
                  className="col d-flex"
                  style={{ color: "#434343" }}
                >
                  <div
                    style={{
                      color: "red",
                      paddingRight: "5px",
                      cursor: "pointer",
                    }}
                  >
                    *
                  </div>
                  <div>
                    <div
                      style={{ cursor: "pointer", fontSize: "14px" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          siteData?.site_styling[0].opt_in_html_small ?? "",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="row d-flex pb-4 justify-content-center">
              <button
                type="button"
                class="btn btn-danger mt-4 pt-2 pb-2 competition"
                onClick={() => submit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BreakModal;
