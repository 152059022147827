import React, { useEffect, useState } from "react";
import HeaderScroller from "../Components/HeaderScroller";
import CompetitionsList from "../Components/CompetitionsList";
import HeaderScrollerMobile from "../Components/HeaderScrollerMobile";
import { useRecoilValue } from "recoil";
import { siteDataAtom } from "../atoms";
import CompetitionCard from "../Components/CompetitionCard";
import BreakModal from "../Components/modals/BreakModal";

function LiveCompetitions() {
  const siteData = useRecoilValue(siteDataAtom);
  const [featured, setFeatured] = useState(0);

  useEffect(() => {
    if (siteData) {
      for (let i = 0; i < siteData.competitions.length; i++) {
        console.log(siteData.competitions[i].id);
        if (
          siteData.site_styling[0].featured_comp_id ===
          siteData.competitions[i].id
        ) {
          setFeatured(i);
        }
      }
    }
  }, [siteData]);

  return (
    <>
      <div className="container-fluid p-0 m-0">
        <div className="row d-none d-lg-flex ">
          <HeaderScroller featured={featured} />
        </div>
        <div className="row d-flex d-lg-none m-0">
          {siteData && <CompetitionCard data={siteData} index={featured} />}
        </div>
        <div
          className="row m-0"
          style={{ backgroundColor: "#E8E8E8", position: "relative" }}
        >
          <CompetitionsList />
        </div>
      </div>
      {siteData && siteData.competitions.length === 0 && <BreakModal />}
    </>
  );
}
export default LiveCompetitions;
