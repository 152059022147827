import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

function FailureFonix() {
  const [tick, setTick] = React.useState(5);

  const history = useHistory();

  const location = useLocation();

  const pathName = location.pathname;
  const site = pathName.split("/")[1];

  useEffect(() => {
    const inter = setInterval(
      () => {
        if (tick > 0) {
          setTick(tick - 1);
        }
      },
      1000,
      1000
    );

    return () => {
      clearInterval(inter);
    };
  }, [tick]);

  if (tick < 1) {
    window.location.href = "/";
  }

  return (
    <div className="fs row m-0">
      <div className="card col-12 text-center d-flex align-items-center justify-content-center">
        <h3>Your entry was not succcessful.</h3>
        <span>If the issue persists, contact support.</span>
        <span>Taking you back to the Competitions</span>
        <div style={{ position: "relative" }}>
          <div
            class="spinner-border text-secondary m-5"
            style={{ height: "50px", width: "50px" }}
            role="status"
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <h3>{tick}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FailureFonix;
