import React, { useEffect, useState } from "react";
import axios from "axios";

function Words() {
  return (
    <div className="container" style={{ maxWidth: "800px" }}>
      <div className="row text-lg pt-5 pb-3 ml-2 pr-2">
        <div>Words</div>
      </div>

      <div className="row pb-5">
        <div>
          Text your first guess to 88588 charged at £2 plus your standard
          network rate. You will have up to six (6) attempts each day to guess
          the correct [five] letter word. The chosen word will change daily and
          the six (6) attempts cannot be carried over to the next day. <br />
          <br />
          After your first attempt, you will be billed £2 plus your network rate
          and you will receive the [5] coloured squares with a green square for
          every correct letter placement, an orange square for every correct
          letter in the incorrect placement and a grey square for every missing
          letter.
          <br />
          <br />
          Each attempt after your first guess shall be charged at your standard
          network rate for each guess sent via text.
          <br />
          <br />
          <br />
          <br />
          If you are successful with any of your six attempts to guess the
          correct answer, you will receive a free success text message and an
          automatic single entry into the associated competition for that day.
          <br />
          <br />
          If you are unsuccessful with your six (6) attempts to guess the
          correct answer, you will receive a free unsuccessful text message and
          will not be entered into the associated competition.
          <br />
          <br />
          Each person is limited to playing Words once per day, and therefore is
          limited to receiving one competition entry per day should they
          correctly guess the answer.
          <br />
          <br />
          The correct Word changes every day at midnight / 00:00 GMT.
        </div>
      </div>
    </div>
  );
}
export default Words;
