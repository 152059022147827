import React, { useEffect } from "react";
import _ from "underscore";
import "../../../App.css";
import "../../../css/EntryOptionsSelector.css";
import "../../../css/EntryModal.css";

const EntryOptionsSelector = (props) => {
  const entryOptions = props.entryOptions;

  const [selected, setSelected] = React.useState(null);

  const entriesText = (number_of_entries) => {
    if (number_of_entries > 1) {
      return "Entries";
    } else {
      return "Entry";
    }
  };

  const render = entryOptions.map((it) => {
    return (
      <div
        className={`wrapper ${
          entryOptions.length > 1 ? "col-6" : "col-12"
        } mt-3`}
        style={{ cursor: "pointer" }}
      >
        <div
          style={{
            borderRadius: "8px",
            height: "100%",
            flexDirection: "column",
          }}
          id="OnlineEntrySelection"
          className={`d-flex shadow  individual justify-content-center align-items-center text-rg text-bold${
            _.isEqual(it, selected) ? " selected-card" : " deselected-card"
          }`}
          onClick={() => [
            setSelected(it),
            window.dataLayer.push({
              event: it.number_of_entries + " Entries - Clicked",
            }),
            props.callback(it),
          ]}
        >
          <div
            className="row  pt-3"
            style={{ fontWeight: 700, fontSize: "15px" }}
          >
            {it.number_of_entries} {entriesText(it.number_of_entries)}
          </div>
          {it.free_entries > 0 && (
            <div
              className={`row text-sm ${
                _.isEqual(it, selected) ? "" : "hilight"
              }`}
              style={{ fontWeight: 600 }}
            >
              + {it.free_entries} FREE {entriesText(it.free_entries)}
            </div>
          )}
          <div className="row text-md pt-3 text-xbold">
            £{(it.amount / 100).toFixed(2)}
          </div>

          <div className="row text-sm pt-3 pb-3" style={{ fontWeight: 500 }}>
            £
            {(
              it.amount /
              (it.number_of_entries + it.free_entries) /
              100
            ).toFixed(2)}{" "}
            per entry
          </div>
        </div>
      </div>
    );
  });

  return (
    <div
      id="EntryOptionsSelector"
      className="row  d-flex justify-content-center w-100"
    >
      {render}
    </div>
  );
};

export default EntryOptionsSelector;
