import axios from "../../../Utils/AppAxios";

/**
 * Make a payment with fonix pay.
 * @param {The option selected to pay} option
 * @param {The site the user is on} site
 * @param {Marketing Option} marketingOpt
 * @returns Promise with details back from server "create_payment_session"
 */
export const payWithFonixPay = (option, site, marketing) => {
  return axios.get("/create_payment_session.php", {
    params: { price_id: option.id, window_id: site, marketing: marketing },
  });
};
