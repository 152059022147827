import React from "react";

const PostEntryDetailsMob = (props) => {
  const entryDetails = props.entryDetails;

  const address = entryDetails.postAddress ? entryDetails.postAddress : {};

  const addressRender = Object.values(address).map((it) => {
    if (it !== "" && it !== null) {
      return (
        <div
          className="text-rg tw-bold"
          style={{ padding: "0px", margin: "0px" }}
        >
          {it}
          <br style={{ height: "3px" }} />
        </div>
      );
    }
  });

  return (
    <div
      id="PostEntryDetailsMob"
      className="d-flex w-100 flex-column justify-content-around align-items-center h-100 pb-5 pt-3 px-3 mt-4"
      style={{ display: "flex", justifyContent: "center", fontSize: "20px" }}
    >
      <div className=" mt-2" style={{ textAlign: "center" }}>
        <p style={{ marginTop: "20px" }}>
          <span className="text-bold mtext text-small">Enter via Post!</span>
        </p>
        <p style={{ marginTop: "20px" }}>
          <span className="mtext px-sm-5 text-center text-xs mt-6 pt-4">
            Send your name and phone number to:
          </span>
        </p>
      </div>

      <div className="d-flex justify-content-center flex-column flex-grow-1">
        <div id="" className="text-md text-bold text-center mtext mt-4 ">
          {addressRender}
        </div>
      </div>
    </div>
  );
};

export default PostEntryDetailsMob;
