import React, { useState, useEffect } from "react";
import { siteDataAtom } from "../atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { Parallax } from "react-parallax";
import { modalAtom } from "../atoms";
import { useHistory } from "react-router-dom";

function CompetitionCard(props) {
  const siteData = useRecoilValue(siteDataAtom);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [modal, setModal] = useRecoilState(modalAtom);
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      if (siteData) {
        var ftime = siteData.competitions[props.index]?.end_time.replace(
          /-/g,
          "/"
        );
        var dateFuture = Date.parse(ftime);
        var dateNow = new Date();
        var seconds = Math.floor((dateFuture - dateNow) / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        if (dateFuture > dateNow) {
          setDays(padDigits(days, 2));
          hours = hours - days * 24;
          setHours(padDigits(hours, 2));
          minutes = minutes - days * 24 * 60 - hours * 60;
          setMins(padDigits(minutes, 2));
          seconds =
            seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
          setSeconds(padDigits(seconds, 2));
        } else {
          setDays("00");
          setHours("00");
          setMins("00");
          setSeconds("00");
        }
      }
    }, 1000);

    console.log(siteData);

    return () => clearInterval(interval);
  }, []);

  function padDigits(number, digits) {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    );
  }

  return (
    <div
      style={{
        borderRadius: "10px",
        overflow: "hidden",
        cursor: "pointer",
        backgroundColor: "white",
      }}
      className="container shadow"
      onClick={() => history.push(siteData.competitions[props.index].slug)}
    >
      <div className="row">
        <img
          src={siteData.competitions[props.index]?.competition_image ?? ""}
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="row pt-3 text-center card-title-text">
        <div
          dangerouslySetInnerHTML={{
            __html: siteData.competitions[props.index]?.competition_title,
          }}
        />
      </div>
      <div className="row d-flex justify-content-center">
        <button
          onClick={() => [
            window.dataLayer.push({
              event:
                "ENTER NOW - " +
                siteData.competitions[props.index]?.competiton_name +
                " - Clicked",
            }),
          ]}
          type="button"
          id="EnterNowButton"
          class="btn btn-danger mt-4 competition"
          style={{ width: "80%", paddingTop: "15px", paddingBottom: "15px" }}
        >
          ENTER NOW
        </button>
      </div>
      {siteData.competitions[props.index]?.sponsor_image && (
        <div className="row pt-4 justify-content-center">
          <img
            style={{ width: "40%" }}
            src={siteData.competitions[props.index].sponsor_image}
            alt="Sponsor Ad"
          />
        </div>
      )}
      <div className="row p-3 pt-2 pb-0 d-flex justify-content-center text-center card-comp-ends-text ends-date">
        {siteData.competitions[props.index]?.entry_price_from}
      </div>
      <div className="row d-flex justify-content-center text-center p-1">
        <div
          className="bubble shadow"
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          <span
            className="pr-2 ends-font ends-date"
            style={{ paddingRight: "5px" }}
          >
            Ends:
          </span>

          <span className="ends-date">
            {siteData.competitions[props.index]?.date_string}
          </span>
        </div>
      </div>
      <div className="row text-sm row justify-content-around card-ends-row pb-3">
        <div className="time-square" style={{ width: "25%" }}>
          <div className="shadow d-flex w-100" style={{ padding: "5px" }}>
            <div>
              <div className="bold-card-text">{days}</div>
              <div className="bold-card-text-sub">Days</div>
            </div>
          </div>
        </div>
        <div className="time-square" style={{ width: "25%" }}>
          <div className="shadow d-flex w-100" style={{ padding: "5px" }}>
            <div>
              <div className="bold-card-text">{hours}</div>
              <div className="bold-card-text-sub">Hrs</div>
            </div>
          </div>
        </div>
        <div className="time-square" style={{ width: "25%" }}>
          <div className="shadow d-flex w-100" style={{ padding: "5px" }}>
            <div>
              <div className="bold-card-text">{mins}</div>
              <div className="bold-card-text-sub">Mins</div>
            </div>
          </div>
        </div>
        <div className="time-square" style={{ width: "25%" }}>
          <div className="shadow d-flex w-100" style={{ padding: "5px" }}>
            <div>
              <div className="bold-card-text">{seconds}</div>
              <div className="bold-card-text-sub">Sec</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CompetitionCard;
