import React, { useEffect, useState } from "react";
import Banner from "./entry/mobile/parts/Banner";
import { useRecoilState } from "recoil";
import { modalAtom, compDataAtom } from "../../atoms";
import { cardDetailsAtom } from "../../atoms";
import axios from "axios";
import { useHistory } from "react-router-dom";

function CardDetails(entryDetails) {
  const [modal, setModal] = useRecoilState(modalAtom);
  const [compData, setCompData] = useRecoilState(compDataAtom);
  const [cardDetails, setCardDetails] = useRecoilState(cardDetailsAtom);
  const [loaded, setLoaded] = useState(false);
  const [host, setHost] = useState("");
  const [checkoutID, setCheckoutID] = useState();
  const [formError, setFormError] = useState("");
  const [price, setPrice] = useState();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    postcode: "",
  });
  const [showCardWidget, setShowCardWidget] = useState(false);

  const history = useHistory();

  function showCardForm() {
    console.log(cardDetails);

    if (formData.firstName.length < 3) {
      setFormError("First name is too short.");
      return;
    }
    if (formData.lastName.length < 3) {
      setFormError("Last name is too short.");
      return;
    }

    if (formData.mobile.length < 5) {
      setFormError("Mobile is too short.");
      return;
    }

    if (formData.address1.length < 1) {
      setFormError("Address Line 1 too short.");
      return;
    }

    if (formData.postcode.length < 3) {
      setFormError("Postcode too short.");
      return;
    }
    if (formData.city.length < 3) {
      setFormError("Town / City too short.");
      return;
    }
    if (formData.email.length < 3) {
      setFormError("Email too short.");
      return;
    }

    formData.checkoutId = checkoutID;
    axios
      .post("https://mirror.win/api/save_customer.php", formData)
      .then(function (response) {
        if (price === 0) {
          window.location.href = "/success";
        }

        const script = document.createElement("script");
        script.src =
          "https://oppwa.com/v1/paymentWidgets.js?checkoutId=" + checkoutID;
        script.async = true;
        document.head.appendChild(script);

        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.innerHTML =
          'var wpwlOptions = { onReady: function() {var emailHtml = \'<div class="wpwl-label wpwl-label-custom" >Email</div>\' + \'<div class="wpwl-wrapper wpwl-wrapper-custom pb-3">\' +\'<input name="customer.email" type="text"></input>\' +\'</div>\'; $(\'form.wpwl-form-card\').find(\'.wpwl-button\').before(emailHtml);},billingAddress: {country: "GB",state:"Unknown",city: "' +
          formData.city +
          '",postcode: "' +
          formData.postcode +
          '",street1: "' +
          formData.address1 +
          '",street2: "' +
          formData.address2 +
          '"},mandatoryBillingFields:{country: true,state: false,city: true,postcode: true,street1: true,street2: false,emailOrAccountId:true}}';
        document.head.appendChild(s);

        setShowCardWidget(true);
      })
      .catch(function (error) {
        if (error.response) {
          setFormError(error.response.data); // => the response payload
        }
      })
      .then(function () {
        // always executed
      });
  }

  useEffect(() => {
    setHost(window.location.hostname);
  }, []);

  const dataChanged = (e) => {
    setFormError("");
    setShowCardWidget(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log(compData);
    console.log(cardDetails);
    var checkoutID = "";
    axios
      .get(
        "https://mirror.win/api/checkout.php?bundle=" +
          cardDetails.selected_bundle.id +
          "&window=" +
          compData.window_id +
          "&marketing=" +
          cardDetails.marketing_opt
      )
      .then(function (response) {
        checkoutID = response.data.checkout_id;
        setCheckoutID(response.data.checkout_id);
        setPrice(response.data.price);

        setLoaded(true);
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  }, []);

  return (
    <div
      class="modal fade show d-flex"
      id="MobileEntryModal"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabindex="-1"
      style={{ backgroundColor: "rgb(0,0,0,0.4)" }}
    >
      <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-l w-100">
        <div class="modal-content rounded-0 w-100 ">
          <div
            class=" m-0 p-0"
            style={{ backgroundColor: "white", paddingTop: "0px" }}
          >
            <div className="row m-0">
              {" "}
              <Banner
                text={cardDetails ? cardDetails.entry_details.entryTitle : ""}
                closeModal={() => setModal()}
              ></Banner>
            </div>

            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <div
                className="col-12 mt-4 shadow"
                style={{
                  backgroundColor: "#F7F7F7",
                  maxWidth: "400px",
                  borderRadius: "8px",
                }}
              >
                <div className="row d-flex justify-content-center pt-3 text-bold ">
                  Purchase Summary
                </div>

                <div
                  className="row d-flex  pt-3 text-bold"
                  style={{
                    padding: "10px",
                    display: "inline-block !important",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{ color: "var(--hilight)", paddingRight: "5px" }}
                    >
                      Prize:
                    </div>
                    <div className="text-sm tw-light">
                      {cardDetails
                        ? cardDetails.entry_details.entryTitle
                            .replaceAll("<b>", "")
                            .replaceAll("</b>", "")
                        : ""}
                    </div>
                  </span>
                </div>
                <div
                  className="row d-flex  pt-0 text-bold"
                  style={{
                    padding: "10px",
                    display: "inline-block !important",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{ color: "var(--hilight)", paddingRight: "5px" }}
                    >
                      Bundle:
                    </div>
                    <div className="text-sm tw-light">
                      {cardDetails
                        ? cardDetails.selected_bundle.description
                            .replace("<b>", "")
                            .replace("</b>", "")
                            .replace("<br>", "")
                        : ""}
                    </div>
                  </span>
                </div>
                <div
                  className="row d-flex  pt-3 text-bold"
                  style={{
                    padding: "10px",
                    display: "inline-block !important",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{ color: "var(--hilight)", paddingRight: "5px" }}
                    >
                      Total Cost:
                    </div>
                    <div className="text-sm tw-light">
                      £
                      {cardDetails
                        ? cardDetails.selected_bundle.amount / 100
                        : ""}
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {loaded === false && (
              <div className="row">
                <div className="row d-flex justify-content-center pt-5 text-bold ">
                  Loading ...
                </div>
              </div>
            )}
            {loaded === true && showCardWidget === false && (
              <>
                <div className="row">
                  <div className="row d-flex justify-content-center pt-5 text-bold ">
                    Customer Details
                  </div>
                </div>

                <div
                  className="row justify-content-center"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div style={{ maxWidth: "400px" }}>
                    <div className="row d-flex  pt-3 text-bold ">
                      * First Name
                    </div>
                    <div className="row d-flex  pt-1 text-bold ">
                      <div
                        style={{
                          borderRadius: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                        className="shadow"
                      >
                        <input
                          placeholder="Enter First Name"
                          id="firstName"
                          name="firstName"
                          type="text"
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            width: "100%",
                          }}
                          disabled={showCardWidget}
                          onChange={(e) => dataChanged(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row justify-content-center"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div style={{ maxWidth: "400px" }}>
                    <div className="row d-flex  pt-3 text-bold ">
                      * Last Name
                    </div>
                    <div className="row d-flex  pt-1 text-bold ">
                      <div
                        style={{
                          borderRadius: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                        className="shadow"
                      >
                        <input
                          placeholder="Enter Last Name"
                          type="text"
                          id="lastName"
                          name="lastName"
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            width: "100%",
                          }}
                          onChange={(e) => dataChanged(e)}
                          disabled={showCardWidget}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row justify-content-center"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div style={{ maxWidth: "400px" }}>
                    <div className="row d-flex  pt-3 text-bold ">
                      * Mobile Number
                    </div>
                    <div className="row d-flex  pt-1 text-bold ">
                      <div
                        style={{
                          borderRadius: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                        className="shadow"
                      >
                        <input
                          placeholder="Enter Mobile Number"
                          type="text"
                          id="mobile"
                          name="mobile"
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            width: "100%",
                          }}
                          onChange={(e) => dataChanged(e)}
                          disabled={showCardWidget}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row justify-content-center"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div style={{ maxWidth: "400px" }}>
                    <div className="row d-flex  pt-3 text-bold ">
                      * Email Address
                    </div>
                    <div className="row d-flex  pt-1 text-bold ">
                      <div
                        style={{
                          borderRadius: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                        className="shadow"
                      >
                        <input
                          placeholder="Enter Email Address"
                          type="text"
                          id="email"
                          name="email"
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            width: "100%",
                          }}
                          onChange={(e) => dataChanged(e)}
                          disabled={showCardWidget}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="row d-flex justify-content-center pt-5 text-bold ">
                    Billing Details
                  </div>
                </div>

                <div
                  className="row justify-content-center"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div style={{ maxWidth: "400px" }}>
                    <div className="row d-flex  pt-3 text-bold ">
                      * Address Line 1
                    </div>
                    <div className="row d-flex  pt-1 text-bold ">
                      <div
                        style={{
                          borderRadius: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                        className="shadow"
                      >
                        <input
                          placeholder="Address Line 1"
                          id="address1"
                          name="address1"
                          type="text"
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            width: "100%",
                          }}
                          disabled={showCardWidget}
                          onChange={(e) => dataChanged(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row justify-content-center"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div style={{ maxWidth: "400px" }}>
                    <div className="row d-flex  pt-3 text-bold ">
                      Address Line 2
                    </div>
                    <div className="row d-flex  pt-1 text-bold ">
                      <div
                        style={{
                          borderRadius: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                        className="shadow"
                      >
                        <input
                          placeholder="Address Line 2"
                          type="text"
                          id="address2"
                          name="address2"
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            width: "100%",
                          }}
                          onChange={(e) => dataChanged(e)}
                          disabled={showCardWidget}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row justify-content-center"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div style={{ maxWidth: "400px" }}>
                    <div className="row d-flex  pt-3 text-bold ">
                      * Town / City
                    </div>
                    <div className="row d-flex  pt-1 text-bold ">
                      <div
                        style={{
                          borderRadius: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                        className="shadow"
                      >
                        <input
                          placeholder="Town / City"
                          type="text"
                          id="city"
                          name="city"
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            width: "100%",
                          }}
                          onChange={(e) => dataChanged(e)}
                          disabled={showCardWidget}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row justify-content-center"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div style={{ maxWidth: "400px" }}>
                    <div className="row d-flex  pt-3 text-bold ">
                      * Postcode
                    </div>
                    <div className="row d-flex  pt-1 text-bold ">
                      <div
                        style={{
                          borderRadius: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                        className="shadow"
                      >
                        <input
                          placeholder="Postcode"
                          type="text"
                          id="postcode"
                          name="postcode"
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            width: "100%",
                          }}
                          onChange={(e) => dataChanged(e)}
                          disabled={showCardWidget}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {formError !== "" && (
                  <div
                    className="row justify-content-center"
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "30px",
                      color: "red",
                    }}
                  >
                    {formError}
                  </div>
                )}
                <div
                  className="row justify-content-center"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <button
                    onClick={() => [showCardForm()]}
                    type="button"
                    class="btn btn-danger mt-4 pt-2 pb-2"
                    disabled={showCardWidget}
                  >
                    Continue
                  </button>
                </div>
              </>
            )}
            {showCardWidget && (
              <div className="row d-flex justify-content-center pt-5 text-bold ">
                Payment Details
              </div>
            )}
            <div
              className="pt-5"
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <form
                action={"https://" + host + "/paymentsuccess"}
                class="paymentWidgets"
                data-brands="VISA MASTER VISADEBIT VPAY VISAELECTRON MAESTRO"
              ></form>
            </div>

            {loaded === true && (
              <div
                id="EntryFooterMob"
                className="  py-3 px-3 mtext text-sm text-bold"
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  textAlign: "center",
                }}
              >
                <p>
                  <span>{compData ? compData.footer_text : "..."}</span>
                </p>
                <p className="mt-3">
                  <span>
                    <u
                      style={{ cursor: "pointer" }}
                      onClick={() => [setModal(), history.push("/terms")]}
                    >
                      Full Terms &amp; Conditions!
                    </u>
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardDetails;
